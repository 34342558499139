.secondcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: rgba(226,221,249,255);


    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 35vh; 
        background: linear-gradient(to top, transparent, rgba(240,238,252,255));
        filter: blur(0px);
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 35vh; 
        background: linear-gradient(to bottom, transparent, rgba(177,164,240,255));
        filter: blur(0px);
    }


}

.cardwalletdiv {
    position: relative;
    width: 80%;
    @media (max-width: 768px) {
        max-width: 70%;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        max-width: 55%; /* Tabletler için genişlik %70 olacak */
    }
}
.cardsec{
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 50%;
    width: 100%;
    height: 100%;
}

.money,
.credit {
    position: absolute;
    top: 70%;
    z-index: -1;
}

.money {
    right: 43%;
    transform: translateY(-100%) rotate(20deg);
    z-index: 1;
}

.credit {
    left: 43%;
    transform: translateY(-100%);
    z-index: 0;
}

.money img,
.credit img {
    width: 100%;
    max-width: 100%;
    max-height: 40vh;
}


.tex {
    margin-top: 5vh;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 3vw 3%;
    span:first-child {
        font-size: 3.5vmax;
        font-weight: 800;
        padding-left: 10px;
        padding-right: 10px;
    }
    
    span:nth-child(2) {
        font-size: 1.5vmax;
        margin-top: 1vh;
        font-weight: 400;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }
}


/* smaller screens */
@media (max-width: 601px) {

    .money,
    .credit {
        position: absolute;
        top: 60%;
        transform: translateY(-70%);
        z-index: -1;
    }


    .money {
        right: 20%;
        transform: translateY(-100%) rotate(20deg);
        z-index: 1;
    }

    .credit {
        left: 20%;
        transform: translateY(-100%);
        z-index: 0;
    }

    .money img,
    .credit img {
        width: auto;
        max-width: 70%;
        max-height: 40vh;
    }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .money,
    .credit {
        position: absolute;
        top: 60%;
        z-index: -1;
    }


    .money {
        right: 35%;
        transform: translateY(-100%) rotate(20deg);
        z-index: 1;
    }
    
    .credit {
        left: 35%;
        transform: translateY(-100%);
        z-index: 0;
    }
    

    .money img,
    .credit img {
        width: auto;
        max-width: 90%;
        max-height: 40vh;
    }
}
