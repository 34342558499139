.cardwallet {
    display: flex;
    flex-direction: column;
    background-color: #C60600;
    border-radius: 20px;
    max-width: 40%;
    width: 100%; 
    height: 100%;
    margin: auto; 
    overflow: hidden;
    position: relative;
    z-index: 2;
    @media (max-width: 768px) {
        max-width: 100%; /* Mobil cihazlar için genişlik %100 olacak */
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        max-width: 100%; /* Tabletler için genişlik %70 olacak */
    }

}

.cartcontent{
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 5%;
    padding-bottom: 5%;
}


.header {
    display: flex;
    justify-content: space-between;
    color: white;
}

.total {
    display: flex;
    align-items: flex-end;
    color: white;
    font-weight: 600;
    margin-top: 10%;
}

.total span:first-child {
    margin-right: 1vw;
    font-size: 1rem;
}

.total span:nth-child(2) {
    font-size: 1.3rem;
}

.buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10%;
}

.buttons button {
    flex: 1; /* adjusted button widths to fill available space */
    padding: 3%;
    font-size: 0.7rem;
    font-weight: 700;
    border-radius: 0.4em;
    border: 0;
    background-color: #ffffff;
}

.buttons button:nth-child(2) {
    margin-left: 7%; /* added margin between buttons */
    color: #E10800;
}
