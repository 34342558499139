.threecontainer {
  width: 100vw;
  height: 95vh;
  align-content: center;
  background-color:rgba(179,166,240,255);
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 15vh;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 30vh; 
    background: linear-gradient(to top, transparent, rgba(177,164,240,255));
    filter: blur(0px);
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 20vh; 
    background: linear-gradient(to bottom, transparent, rgba(165,151,237,255));
    filter: blur(0px);
  }


  .text {
    display: flex;
    flex-direction: column;
    padding: 3vw 3%;

    span:first-child {
      font-size: 3.5vmax;
        font-weight: 800;
        padding-left: 10px;
        padding-right: 10px;
    }

    span:nth-child(2) {
      font-size: 1.5vmax;
      margin-top: 1vh;
      font-weight: 400;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;    }
  }


}

.hexagon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hexagon-icon {
  color: #c60700; //#B3EEE9
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 7vmax;
  height: 7vmax;
}

@media (max-width: 601px) {
  .threecontainer {
    .iphone {
      border-top-left-radius: 5vmax;
      border-top-right-radius: 5vmax;
    }
  }

}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .threecontainer {
    height: 85vh;

    .iphone {
      border-top-left-radius: 5vmax;
      border-top-right-radius: 5vmax;
    }
  }
}