@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@keyframes moveCloudsHome {
    0% {
        background-position: 150% 50vh, 120% 25vh, 130% 27vh, -170% 70vh, 55% 60vh, 400% 75vh;
    }

    100% {
        background-position: -30% 50vh, -30% 25vh, -30% 27vh, -170% 70vh, 55% 60vh, 400% 75vh;
    }
}

.homecontainer {
    height: 130vh;
    width: 100vw;
    display: flex;
    overflow: hidden;
    justify-content: center;
    position: relative;


    background: url('../../image/could.png'),
        url('../../image/could.png'),
        url('../../image/could.png'),
        url('../../image/could.png'),
        url('../../image/could.png'),
        url('../../image/could.png');
    background-color: rgba(130,110,230,255);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: 30vh auto, 34vh auto, 44vh auto, 190vh auto, 190vh auto, 190vh auto;
    background-position: 100vh, 100vh, 500vh, 1000vh, 1000vh, 1000vh;
    animation: moveCloudsHome 30s linear infinite;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height:30vh; // blur effect height
        background: linear-gradient(to bottom, transparent, rgba(240,238,252,255)); 
        filter: blur(-100px); 
    }

    .texts {
        height: auto;
        display: flex;
        flex-direction: column;
        margin-top: 20vh;
        color: white;
        width: 100%;
        text-align: center;
        span:first-child,
        span:nth-child(2) {
            text-transform: uppercase;
            font-size: 7vmax;
            font-family: 'Bebas Neue', sans-serif !important;
        }
        
        span:nth-child(2) {
            margin-top: 0;
        }

        span:nth-child(3) {
            font-family: 'AppleBraille', sans-serif;
            padding-left: 5vw;
            padding-right: 5vw;
            padding: 6vh 25vw 0 25vw;
        }

        button {
            margin: 4vh auto 0 auto;
            padding: 2vh 4vw 2vh 4vw;
            font-size: 1rem;
            border-radius: 30px;
            border: 0;
            background-color: black;
            color: white;
            font-family: 'AppleBraille', sans-serif;

        }
    }

    /* phone hexagon */
    @media only screen and (max-width: 600px) {
        .texts{
            span:first-child{
                padding-left: 2%;
                padding-right: 2%;
   
            }

        }
        .hexagonone,
        .hexagontwo,
        .hexagonthree {
            position: absolute;
            display: inline-block;
            transform: translate(-50%, -50%);
        }

        .hexagonone {
            margin-top: 6vh;
        }

        .hexagontwo {
            margin-top: 23vh;
            margin-right: -9vw;
        }

       
        .inner-icon {
            width: 7vh;
        }

        .inner-staticicon {
            width: 3vh;
        }
    }

    /* tablet hexagon*/
    @media only screen and (min-width: 601px) and (max-width: 1024px) {
        .hexagonone {
            margin-top: 6vh;
        }

        .hexagontwo {
            margin-top: 13vh;
            margin-right: -6vw;
        }

        .hexagonthree {
            position: relative;
        }

        .inner-icon {
            width: 7vh;
        }

        .inner-staticicon {
            width: 3vh;
        }
    }

    /* computer hexagon*/
    @media screen {
        .hexagonone {
            position: absolute;
            display: inline-block;
            /* allows the container to fit the size of the icons */
            top: 26.6vh;
            /* Adjust these values as needed */
            left: 16.5vw;
            transform: translate(-50%, -50%);

            /* Centers the hexagon */
            .hexagon-icon {
                color: #B5AEE2;
            }

            .inner-icon {
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
            }
        }

        .hexagontwo {
            position: absolute;
            display: inline-block;
            top: 26.6vh;
            right: 7.5vw;
            transform: translate(-50%, -50%);
            /* Centers the hexagon */
            justify-content: center;
            align-content: center;

            .hexagon-icon {
                color: #BFB8EF;
            }

            .inner-icon {
                position: absolute;
                top: 50%;
                left: 47%;
                transform: translate(-50%, -50%);
                color: white;
            }
        }

        .hexagonthree {
            position: relative;
            display: inline-block;
            bottom: 0%;
            right: 20vw;
            justify-content: center;
            align-content: center;

            .hexagon-icon {
                color: #B5AEE2;
            }

            .inner-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
            }

            .inner-staticicon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
            }

        }
    }

}