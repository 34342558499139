@keyframes moveClouds {
    0% {
        background-position: 150% 50%, 120% 25%, 130% 27%, -170% 65vh, 75% 70vh, 200% 75vh;
    }

    100% {
        background-position: -30% 50%, -30% 25%, -30% 27%, -170% 65vh, 75% 70vh, 200% 75vh;
    }
}

@import url("https://use.typekit.net/lhu4xoj.css");

.fourthcontainer {
    height: 90vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    background: url('../../image/could.png'),
        url('../../image/could.png'),
        url('../../image/could.png'),
        url('../../image/could.png'),
        url('../../image/could.png'),
        url('../../image/could.png');
    background-color: #BBB1F2;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: 30vh auto, 34vh auto, 44vh auto, 190vh auto, 190vh auto, 190vh auto;
    background-position: 100vh, 100vh, 500vh, 1000vh, 1000vh, 1000vh;
    animation: moveClouds 30s linear infinite;
    position: relative;
    background-color: rgba(130, 110, 230, 255);
    padding-top: 20vh;
    padding-bottom: 20vh;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 20vh;
        background: linear-gradient(to top, transparent, rgba(165, 151, 237, 255));
        filter: blur(0px);
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 15vh;
        background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.47));
        filter: blur(0px);
    }

    .iphone {
        width: 300px; /* 6.1 inç iPhone genişliği */
        height: auto; /* 6.1 inç iPhone yüksekliği */
        border-radius: 40px;
        background-color: #ffffffa0;
        border: 0.7px solid #fff;
        display: flex;
        flex-direction: column;
        @media (max-width: 768px) {
            max-width: 80%; /* Mobil cihazlar için genişlik %100 olacak */
        }
        .iphonespan {
            margin-top: 0px;
            color: black;
            display: flex;
            align-items: center;
            font-weight: 500;
            padding: 1vmax;
            justify-content: center;
        }

        .notch {
            width: 35%;
            max-width: 30vw;
            height: 23px;
            background-color: #fff !important;
            border-radius: 30px;
            position: relative;
            margin-right: 1vmax;
            margin-left: 2vmax;
        }

        .iphoneheader {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-right: 1vmax;
            margin-left: 1.5vmax;
            span:first-child {
                font-size: 1.3rem;
            }

            span:nth-child(2) {
                color: #1D1D1D;
                font-size: 1rem;

            }
        }

        .cardwalletcontainer {
            margin-top: 1vmax;
            margin-bottom: 1vmax;
            display: flex;
            flex-direction: row;
            align-self: center;
            width: 700px;
            height: 100%;
            @media (max-width: 768px) {
                max-width: 90%; /* Mobil cihazlar için genişlik %100 olacak */
            }

            @media (min-width: 769px) and (max-width: 1024px) {
                max-width: 90%; /* Tabletler için genişlik %70 olacak */
            }
        }

        .iphoneicons {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;
            gap: 1.8rem;
            margin-top: 0.6%;

            .image-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 0.55rem;

                .caption {
                    margin-top: 1vh;
                    font-family: 'AppleBraille', sans-serif;
                }
            }
        }

        .line {
            width: 100%;
            border: 0.5px solid #FEFEFE;
            margin-top: 7px;
            margin-bottom: 7px;
            box-sizing: border-box;
            position: relative;
        }

        .assetdiv {
            width: 100%;
            box-sizing: border-box;
            background-color: #fcfcfcd4;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 0.6rem;
            height: auto;
            padding: 0.5rem 1rem;
            position: relative;

            span:first-child {
                font-weight: 900;
                border-bottom: 1px solid black;
                padding-bottom: 0.2vmax;
                display: inline-block;
            }
        }

        .coins {
            width: 85%;
            height: auto;
            margin: 1.9% auto;
            background-color: #fcfcfc91;
            border: 1px solid #70707022;
            border-radius: 12px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 2%;
            padding: 3%;
            .coin-box {
                width: auto;
                height:auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: #fcfcfcc4;
                border: 1px solid #70707041;
                border-radius: 10px;
                gap: 6px;
                padding-top: 15px;
                padding-bottom: 15px;
            }

            .coin-box img {
                width: 30%;
                height: auto;
            }

            .coin-box span {
                font-size: 0.5rem;
            }
        }

        .bottomnavbar {
            display: flex;
            align-self: center;
            justify-content: center;
            height: 10%;
            max-height: 50px;
            width: 90%;
            max-width: 390px;
            background-color: #fcfcfc7f;
            border-radius: 30px;
            box-sizing: border-box;
            gap: 1.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            .navbardiv {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .navbardiv img {
                width: 1.3rem;
                height: 1.1rem;
                margin-bottom: 0.7vh;
            }
        }

        .bottomline {
            width: 30%;
            border-bottom: 2px solid black;
            border-radius: 10px;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            align-self: center;
            position: relative;
        }
    }

    .text {
        margin-bottom: 3vh;

        span {
            color: white;
            font-size: 3em;
            font-weight: 600;
            font-family: "acumin-pro", sans-serif;
            font-style: normal;
            padding: auto;
        }
    }

    .download {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        max-width: 50%;
        margin-top: 3vh;
        @media (max-width: 768px) {
            max-width: 100%; /* Mobil cihazlar için genişlik %100 olacak */
        }

        @media (min-width: 769px) and (max-width: 1024px) {
            max-width: 70%; /* Tabletler için genişlik %70 olacak */
        }
        .appstore,
        .playstore {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: #efedffbe;
            padding: 10px 29px;
            border: 0;
            border-radius: 40px;
            gap: 10px;
            text-align: left;
        }

        .appstoretext,
        .playstoretext {
            display: flex;
            flex-direction: column;
            font-family: "acumin-pro", sans-serif;
            font-style: normal;
            justify-content: center;
            align-items: start;
            span:first-child {
                font-size: 0.7em;
            }

            span:nth-child(2) {
                font-size: 1em;
            }
        }
    }
}
